import React from 'react';
import { palette } from '../../themes';

export interface ImageProps {
  imageSrc: string;
  imageAlt: string;
  imageWidth?: string,
  imageHeight?: string,
  imagePreview?: boolean;
  imageonLoad?: () => any;
  imageStyle?: any;
  fileBorder?: any;
}

const Image = ({ imageSrc, imageAlt, imagePreview, fileBorder, imageStyle, imageonLoad, imageWidth, imageHeight }: ImageProps) => {
  return (
    <span style={{

      border: fileBorder ? `1px solid ${palette.lightBlack}` : 0,
      display: "inline-block",
    }}
    >
      <img src={imageSrc} alt={imageAlt}
        style={{ width: (imageWidth && imageWidth) || (imagePreview && "20px") || "200px", height: imageHeight ? imageHeight : "100%", objectFit: imageWidth ? "contain" : "", ...imageStyle }}
        onLoad={imageonLoad}
      />

    </span >
  )
};

export { Image };

