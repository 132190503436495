import { default as MButton } from '@material-ui/core/Button';
import React, { CSSProperties } from 'react';
import { fonts, palette } from '../../themes/index';

export interface ButtonProps {
  children?: any;
  buttonPrimary?: boolean;
  buttonTextColor?: string;
  buttonStyle?: CSSProperties;
  buttonTextExtraSpaceUnit?: string;
  onClick?: (e: any) => any;
  buttonNoRippleEffect?: boolean;
  buttonFullWidth?: boolean;
}

const Button = ({ buttonFullWidth, buttonNoRippleEffect, children, buttonPrimary, buttonTextColor, buttonTextExtraSpaceUnit, onClick, buttonStyle }: ButtonProps) => {
  const extraSpace = buttonTextExtraSpaceUnit ? buttonTextExtraSpaceUnit : '10px'
  return (<MButton fullWidth={buttonFullWidth ? true : false} disableRipple={buttonNoRippleEffect ? true : false} style={{ textTransform: 'none', fontFamily: fonts.primary, backgroundColor: buttonPrimary ? palette.primary : palette.secondary, color: buttonTextColor ? buttonTextColor : buttonPrimary ? palette.white : palette.black, paddingLeft: extraSpace, paddingRight: extraSpace, ...buttonStyle }} onClick={onClick}>{children}</MButton >)
};

export { Button };
