import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { IconButton } from '../IconButton';


export type Props = {
    onCloseDialog: () => any;
    onSaveDialog: () => any;
    open: any;
    children: any;
    style?: any;
    dialogButton?: boolean;
    dialogTitle?: boolean;
    dialogTitleText?: string;
    fullWidth?: boolean;
};

const competentieStyles = createStyles({
    omschrijving: {
        background: '#F0F0F0',
        padding: 10,
        fontSize: 16,
        marginLeft: 30,
        width: "95%",
    },
    competentieNaam: {
        marginLeft: 30,
        marginTop: 30,
        paddingBottom: 20,
        textTransform: "uppercase",
        fontWeight: "bold",
    },
    competentieRadioButton: {
        marginLeft: 40,
        width: "95%",
    }
});

const DialogLabelButton = withStyles(competentieStyles)(({ fullWidth, dialogTitleText, dialogTitle, dialogButton, onCloseDialog, onSaveDialog, children, open, ...props }: Props) => {

    return (
        <Dialog
            open={open}
            fullWidth={fullWidth ? true : false}>
            {
                dialogTitle ?
                    <DialogTitle>{dialogTitleText}</DialogTitle>
                    :
                    ""
            }

            <DialogContent>
                {children}
            </DialogContent>

            <DialogActions>
                {dialogButton && fullWidth ?
                    <span>
                        <IconButton
                            iconButtonText="Sluit"
                            onClick={onCloseDialog}
                            iconName={"close"}
                        />
                    </span>
                    :
                    ""
                }
            </DialogActions>
        </Dialog>
    );
}
)

export default DialogLabelButton;