import { History } from 'history';
import React, { useState } from 'react';
import useForm from 'react-hook-form';
import * as yup from 'yup';
import { auth } from '../../../utils/firebase';
import { Label } from '../../atoms/Label';
import DialogLabelButton from '../../molecules/DialogLabelButton';
import { RecoveryForm } from '../../organisms/RecoveryForm';
import NoUiTemplate from '../../templates/NoUiTemplate';

interface RecoveryPageProps {
  history?: History
}

const RecoveryValidationScheme = yup.object().shape({
  email: yup.string().required("veld is verplicht").email("veld moet email zijn"),
});


const RecoveryPage = ({ history }: RecoveryPageProps) => {

  const [dialog, setDialog] = useState(false);


  const { register, watch, errors, triggerValidation } = useForm({
    mode: "onBlur",
    validationSchema: RecoveryValidationScheme
  })


  const recover = async () => {
    try {
      const { email } = watch()

      auth.sendPasswordResetEmail(email as string).then(() => setDialog(true)).then(() => setTimeout(() => setDialog(false), 700));


    } catch (err) {
      console.log(err)
    }
  };

  return (
    <NoUiTemplate>
      <RecoveryForm
        regRef={register}
        errors={errors}
        onRecoveryClick={async () => {
          const validated = await triggerValidation()
          if (validated === true) {
            recover()
          }
        }
        }
        onRecoveryBack={() => {
          history && history.push("/login")
        }}
      />
      <DialogLabelButton
        open={dialog}
        onCloseDialog={() =>
          setDialog(false)
        }
        onSaveDialog={() => console.log("Saved")}
      >
        <Label> Verzonden </Label>
      </DialogLabelButton>
    </NoUiTemplate >
  )
}

export default RecoveryPage