import { History } from 'history'
import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useCollection } from 'react-firebase-hooks/firestore'
import useForm from 'react-hook-form'
import * as yup from 'yup'
import { ProfilesModel } from '../../../models/profiles'
import { auth, firestore } from '../../../utils/firebase'
// import DialogLabelButton from '../../molecules/DialogLabelButton';
import { Label } from '../../atoms/Label'
import DialogLabelButton from '../../molecules/DialogLabelButton'
import { EditAddProfile } from '../../organisms/EditAddProfile'
import { SideNavigation } from '../../organisms/SideNavigation'
import AdminTemplate from '../../templates/AdminTemplate/index'
import { palette } from '../../themes'

interface EditProfileProps {
  history?: History
}


const EditProfilePage = ({ history, ...props }: EditProfileProps) => {
  const [dialog, setDialog] = useState(false)

  //auth hook 
  const [user] = useAuthState(auth)

  //data hook
  const [value, loading] = useCollection(
    firestore.collection('profiles').where('userId', '==', (user && user.uid) || ""),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )

  //auth error state
  const [authError, setAuthError] = useState<string>()

  //validation scheme
  const ProfileValidationScheme = yup.object().shape({
    firstname: yup.string().required("veld is verplicht"),
    familyname: yup.string().required("veld is verplicht"),
    email: yup.string().required("veld is verplicht").email("veld moet email zijn"),
    birthdate: yup.date().typeError("veld moet geldig datum zijn").required("veld is verplicht"),
    address: yup.string().required("veld is verplicht"),
    telephone: yup.string().required("veld is verplicht"),
    country: yup.string().required("veld is verplicht"),
    role: yup.string().oneOf(["", "Architect", "Aannemer", "Ramenfabrikant", "Andere"]),
    company: yup.string().required("veld is verplicht")
  })

  //form hook
  const { register, watch, errors, setValue, triggerValidation } = useForm({
    mode: "onBlur",
    validationSchema: ProfileValidationScheme
  })

  //selects callback
  const [selectedValues, setSelectValue] = useState({ role: "" })
  const handleMultiChange = (selectedOption: any) => {
    console.log(selectedOption.target.value, "role", selectedOption.target.name)
    setValue(selectedOption.target.name, selectedOption.target.value)
    setSelectValue({ role: selectedOption.target.value })
  }
  useEffect(() => {
    register({ name: "role" })
  }, [register])


  //load initial values 
  const setValues = (obj: { [key: string]: string }) => {
    Object.keys(obj).forEach(itemkey => {
      setValue(itemkey, obj[itemkey])
    })
  }

  //data to form 
  useEffect(
    () => {
      const a = value && value.docs && value.docs[0] && value.docs[0].data()
      if (a) {
        setValues(a)
        setSelectValue({ role: a.role || "" })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value])


  //save 
  const Save = async () => {
    try {
      const data = await firestore.collection("profiles").where('userId', '==', (user && user.uid) || "").get()
      const id = data.docs[0].id
      const dataUpdate: ProfilesModel = {
        ...watch()
      }
      console.log(dataUpdate)
      id && firestore.collection("profiles").doc(id).update(dataUpdate).then(() => setDialog(true)).then(() => setTimeout(() => setDialog(false), 700))
      setDialog(true)
    }
    catch (err: any) {
      setAuthError(err["message"] || "unknown")
    }
  }

  return (
    <div>
      <AdminTemplate
        adminNavmenu={<SideNavigation sideNavigationSelectedValue={"Account"} sideNavigationOnClick={(menu) => history && history.push(menu)} />}
        contentCenter
      >
        {authError &&
          <div><p style={{ color: palette.danger }}>Error: {authError}</p></div>
        }

        {user &&
          <div>
            <p>Gebruiker:<br /> {user.email}</p>
          </div>
        }

        {user && !loading &&
          <span>
            <EditAddProfile
              onProfileSave={async () => {
                const validated = await triggerValidation()
                if (validated === true) {
                  console.log(validated, "form validation result")
                  Save()
                }
              }
              }
              onProfileLogOut={() =>
                auth.signOut()
              }
              errors={errors}
              regRef={register}
              onSelectChange={handleMultiChange}
              selectValues={selectedValues}
            />
            <DialogLabelButton
              open={dialog}
              onCloseDialog={() =>
                setDialog(false)
              }
              onSaveDialog={() => console.log("Saved")}
            >
              <Label> Opgeslagen </Label>
            </DialogLabelButton>
          </span>
        }
      </AdminTemplate>
    </div>
  )
}
export default EditProfilePage
