import React from 'react';
import { Button } from '../../atoms/Button';
import { Col } from '../../atoms/Col';
import { Form } from '../../atoms/Form';
import { Row } from '../../atoms/Row';
import { Space } from '../../atoms/Space';
import { InputLabel } from '../../molecules/InputLabel';


interface RecoveryFormProps {
    onRecoveryClick?: () => any
    regRef?: (params: any) => void;
    errors?: any;
    onRecoveryBack?: () => any;
}

export interface LoginFormDataScheme {
    username?: string
    password?: string;
}

const RecoveryForm = ({ onRecoveryBack, onRecoveryClick, errors, regRef }: RecoveryFormProps) => {
    return (
        <Form>
            <Row>
                <Col colSize="col-xs-12 col-lg-12 center-xs">
                    {errors && errors.username && <span>{errors.username.message || "Fout"}</span>}
                    <InputLabel regRef={regRef} name="email" inputFullWidth inputType="email" inputLabel="Email" inputError={errors && errors.email && errors.email.message} />
                </Col>
                <Col colSize="col-xs-12 center-xs">
                    <Space />
                    <Button buttonFullWidth onClick={onRecoveryClick}>Wachtwoord verzenden</Button>
                </Col>
                <Col colSize="col-xs-12 center-xs">
                    <Space />
                    <Button buttonFullWidth onClick={onRecoveryBack}>Terug</Button>
                </Col>
            </Row>
        </Form>

    )
}

export { RecoveryForm };

