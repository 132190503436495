import React from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { firestore } from '../../../utils/firebase';
import { Col } from '../../atoms/Col';
import { Row } from '../../atoms/Row';
import { Space } from '../../atoms/Space';
import { RequestList } from '../../organisms/RequestList';


export interface RequestPageProps {
    id?: string | undefined;
}


const RequestPage = ({ id }: RequestPageProps) => {

    const [snapshot] = useCollection(firestore.collection("requests").where("productId", "==", id));
    const retData = snapshot && snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))


    return (
        <Row rowOptions="around-xs">
            <Col colSize="col-xs-11">
                <Space />
                <RequestList rows={retData || []} />
            </Col>
        </Row>
    )
}


export default RequestPage
