import React from 'react'
import { Col } from '../../atoms/Col'
import { Dropdown } from '../../atoms/Dropdown'
import { Form } from '../../atoms/Form'
import { Row } from '../../atoms/Row'
import { Space } from '../../atoms/Space'
import AddressAutocompleteInput from '../../molecules/AddressAutocompleteInput/AddressAutocompleteInput'
import { CheckboxLabel } from '../../molecules/CheckboxLabel'
import { InputLabel } from '../../molecules/InputLabel'
import { palette } from '../../themes'

export interface NewProductProps {
    regRef?: (params: any) => void;
    errors?: any;
    onSelectChange?: (e: any) => any;
    selectValues?: any;
    path?: string;
    location?: string;
    onLocationChange?: (location: string) => unknown;
}


export interface NewProductPropsDataScheme {
    articleName?: string
    articleDescription?: string;
    quantity?: Number;
    quantityAvailable?: Number;
    pricePerPiece?: string;
    priceAll?: string;
    availableFrom?: Date;
    transportation?: boolean;
    status?: string;
    category?: string;
    serie?: string;
    quantitySold?: string;
    offerPrice?: boolean;
    location?: string;
}

const records = [{ name: " Jansen-profiel", value: 'Jansen-profiel', }, { name: 'constructieprofiel', value: 'constructieprofiel' }, { name: 'deur', value: 'deur' }, { name: 'raam', value: 'raam' }, { name: 'geveldeel', value: 'geveldeel' }, { name: 'overige', value: 'overige' }]
const serieRecords = [{ name: "ART-15", value: 'ART-15', }, { name: 'Janisol ARTE', value: 'Janisol ARTE' }, { name: 'Janisol', value: 'Janisol' }, { name: 'Janisol HI', value: 'Janisol HI' }, { name: 'Janisol 2', value: 'Janisol 2' }, { name: 'Janisol C4', value: 'Janisol C4' }, { name: 'Jansen VISS', value: 'Jansen VISS' }, { name: 'Economy', value: 'Economy' }, { name: 'Overige', value: 'Overige' }]
const statusRecords = [{ name: "Beschikbaar", value: "niet verkocht" }, { name: "niet-beschikbaar", value: "verkocht" }]

const NewProduct = ({ selectValues, onSelectChange, regRef, errors, path, location, onLocationChange, ...props }: NewProductProps) => {

    return (
        <Form>
            <Row rowOptions="around-xs">
                <Col colSize="col-xs-5">

                    <InputLabel shrink name="articleName" regRef={regRef} inputError={(errors && errors.articleName && errors.articleName.message) || ""} inputFullWidth inputType="text" inputLabel="Artikelnaam" inputRequired />
                    <InputLabel shrink name="articleDescription" regRef={regRef} inputError={(errors && errors.articleDescription && errors.articleDescription.message) || ""} inputFullWidth inputType="text" inputLabel="Omschrijving" inputRequired />
                    <InputLabel shrink name="quantity" regRef={regRef} inputError={(errors && errors.quantity && errors.quantity.message) || ""} inputFullWidth inputType="number" inputLabel="Aantal" inputRequired />
                    <InputLabel shrink name="quantityAvailable" regRef={regRef} inputError={(errors && errors.quantityAvailable && errors.quantityAvailable.message) || ""} inputFullWidth inputType="number" inputLabel="Aantal stuks beschikbaar" inputRequired />
                    <InputLabel shrink name="pricePerPiece" inputDisabled={(selectValues && selectValues.offerPrice) || false} regRef={regRef} inputError={(errors && errors.pricePerPiece && errors.pricePerPiece.message) || ""} inputFullWidth inputType="number" inputLabel="Prijs per stuk (€)" />
                    <InputLabel shrink name="priceAll" inputDisabled={(selectValues && selectValues.offerPrice) || false} regRef={regRef} inputError={(errors && errors.priceAll && errors.priceAll.message) || ""} inputFullWidth inputType="number" inputLabel="Totaalprijs (€)" />
                    <Space />
                    <InputLabel shrink name="availableFrom" regRef={regRef} inputError={(errors && errors.availableFrom && errors.availableFrom.message) || ""} inputFullWidth inputType="date" inputLabel="Beschikbaar vanaf" inputRequired />
                    <Space />
                    <Space />

                    <Row>
                        <CheckboxLabel name="transportation" regRef={regRef}
                            checkboxChecked={selectValues && selectValues.transportation ? true : false}
                            checkboxLabelText="ja"
                            checkboxLabelTitle="Transport inbegrepen?"
                            checkboxLabelStyle={{ marginLeft: '10px' }}
                            labelStyle={{ color: palette.grey }}
                            checkboxChanged={(e) => onSelectChange && onSelectChange(e)} />
                    </Row>

                    {(path && path !== "/products/new") &&
                        <>
                            <br />
                            <InputLabel shrink name="ownerEmail" regRef={regRef} inputError={(errors && errors.ownerEmail && errors.ownerEmail.message) || ""} inputFullWidth inputType="text" inputLabel="Extra Informatie" inputDisabled />
                            <InputLabel shrink name="ownerFirstName" regRef={regRef} inputError={(errors && errors.ownerFirstName && errors.ownerFirstName.message) || ""} inputFullWidth inputType="text" inputLabel="" inputDisabled />
                            <InputLabel shrink name="ownerLastName" regRef={regRef} inputError={(errors && errors.ownerLastName && errors.ownerLastName.message) || ""} inputFullWidth inputType="text" inputLabel="" inputDisabled />
                        </>
                    }
                    <Space />
                </Col>
                <Col colSize="col-xs-5 ">
                    <Dropdown
                        disabledText="Status"
                        dropdownDefaultValue={selectValues && selectValues.status}
                        dropdownFullWidth
                        dropdownLabel={"Status"}
                        dropdownName="status"
                        dropdownOnChange={(e) => onSelectChange && onSelectChange(e)}
                        dropdownRecords={statusRecords}
                        regRef={regRef}
                        required
                    />
                    <Space />
                    <Dropdown
                        disabledText="Kies een categorie"
                        dropdownDefaultValue={selectValues && selectValues.category}
                        dropdownDisplayEmpty
                        dropdownFullWidth
                        dropdownLabel={"Categorie"}
                        dropdownName="category"
                        dropdownOnChange={(e) => onSelectChange && onSelectChange(e)}
                        dropdownRecords={records}
                        regRef={regRef}
                        required
                    />
                    <Space />
                    <Dropdown
                        disabledText="Kies een serie"
                        dropdownDefaultValue={selectValues && selectValues.serie}
                        dropdownDisplayEmpty
                        dropdownFullWidth
                        dropdownLabel={"Serie"}
                        dropdownName="serie"
                        dropdownOnChange={(e) => onSelectChange && onSelectChange(e)}
                        dropdownRecords={serieRecords}
                        regRef={regRef}
                        required
                    />
                    <Space />
                    <InputLabel shrink name="quantitySold" regRef={regRef} inputError={(errors && errors.quantity && errors.quantity.message) || ""} inputFullWidth inputType="number" inputLabel="Aantal stuks verkocht" />
                    <Space />
                    <Space />
                    <Row rowOptions={"row-xs-12"}>
                        <CheckboxLabel name="offerPrice" regRef={regRef}
                            checkboxChecked={selectValues && selectValues.offerPrice ? true : false}
                            checkboxLabelText="ja"
                            checkboxLabelTitle="Prijs overeen te komen"
                            checkboxLabelStyle={{ marginLeft: '10px' }}
                            labelStyle={{ color: palette.grey }}
                            checkboxChanged={(e) => onSelectChange && onSelectChange(e)} />
                    </Row>

                    <AddressAutocompleteInput name="location" regRef={regRef} error={(errors && errors.location && errors.location.message) || ""} label="Locatie" required value={location} onChange={onLocationChange}/>
                </Col>
            </Row>
        </Form>
    )
}


export { NewProduct }

