import React from 'react';
import { default as MTableCell } from '@material-ui/core/TableCell';

export interface TableCellProps {
  align?: "center" | "left" | "right"
}


const TableCell = ({ children, align }: any) => {
  return (<MTableCell align={align} >{children}</MTableCell>)
};

export { TableCell }