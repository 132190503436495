import { History } from 'history'
import React, { useContext, useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useCollection, useDocument } from 'react-firebase-hooks/firestore'
import useForm from 'react-hook-form'
import { match } from 'react-router'
import uuidv1 from 'uuid'
import * as yup from 'yup'
import { ProductModel } from '../../../models/products'
import UserContext from '../../../utils/context'
import { auth, firestore, storage } from '../../../utils/firebase'
import { Label } from '../../atoms/Label'
import DialogLabelButton from '../../molecules/DialogLabelButton'
import confirm from '../../organisms/Confirm'
import { ProductSwitch } from '../../organisms/ProductSwitch'
import { SideNavigation } from '../../organisms/SideNavigation'
import AdminTemplate from '../../templates/AdminTemplate'

interface EditProfileProps {
  history?: History,
  match?: match<{ id: string }>
}


const ProductPage = ({ match, history, ...props }: EditProfileProps) => {

  const [mySelected, setSelected] = useState("algemene informatie")

  const [user] = useAuthState(auth)

  //data hook
  const [value] = useCollection(
    firestore.collection('profiles').where('userId', '==', (user && user.uid) || ""),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )




  const [dialog, setDialog] = useState(false)

  //photos 
  const [photos, setPhotos] = useState([])

  //files 
  const [files, setFiles] = useState([])


  let userData = useContext(UserContext)

  //here begin 

  //validation scheme
  const ProductValidationScheme = yup.object().shape({
    articleName: yup.string().required("Artikelnaam  veld is verplicht"),
    articleDescription: yup.string().required("Omschrijving veld is verplicht"),
    quantity: yup.number().transform(cv => isNaN(cv) ? undefined : cv).required("Aantal veld is verplicht"),
    quantityAvailable: yup.number().transform(cv => isNaN(cv) ? undefined : cv).required("Aantal stuks beschikbaar veld is verplicht"),
    pricePerPiece: yup.number().transform(cv => isNaN(cv) ? undefined : cv).notRequired().typeError("Prijs per stuk fout"),
    priceAll: yup.number().transform(cv => isNaN(cv) ? undefined : cv).notRequired().typeError("Totaalprijs fout"),
    availableFrom: yup.date().typeError("Beschikbaar vanaf veld fout").required("Beschikbaar vanaf veld is verplicht"),
    transportation: yup.boolean().typeError("Transport inbegrepen veld is verplicht"),
    status: yup.string().required("Status veld is verplicht"),
    category: yup.string().required("Categorie veld is verplicht"),
    serie: yup.string().required("Serie veld is verplicht"),
    quantitySold: yup.number().transform(cv => isNaN(cv) ? undefined : cv).typeError("Aantal stuks verkocht fout"),
    offerPrice: yup.boolean().typeError("Prijs overeen te komen veld is verplicht"),
    location: yup.string().required("Locatie veld is verplicht"),
    weight: yup.number().transform(cv => isNaN(cv) ? undefined : cv).typeError("Gewicht fout"),
    height: yup.number().transform(cv => isNaN(cv) ? undefined : cv).typeError("Hoogte fout"),
    color: yup.string().typeError("kleur fout"),
    fireResistant: yup.string().typeError("Brandwerend fout"),
    ral: yup.string().typeError("RAL fout"),
    length: yup.number().transform(cv => isNaN(cv) ? undefined : cv).typeError("Lengte (profiel) fout"),
    width: yup.number().transform(cv => isNaN(cv) ? undefined : cv).typeError("Breedte fout"),
    material: yup.string().typeError("Materiaal fout"),
    uValue: yup.string().typeError("U-waarde fout"),
    glass: yup.string().typeError("Samenstelling glas fout")
  })



  //form hook
  const { register, watch, errors, setValue, triggerValidation } = useForm<any>({
    mode: "onBlur",
    validationSchema: ProductValidationScheme,
    defaultValues: {
      status: "niet verkocht"
    }
  })


  //selects and checkbox callback
  const [selectedValues, setSelectValue] = useState({ status: "niet verkocht", category: "Kies categorie", serie: "Kies serie", transportation: false, offerPrice: false })
  const handleMultiChange = (selectedOption: any) => {
    const value = selectedOption.target.type === "checkbox" ? selectedOption.target.checked : selectedOption.target.value
    const name = selectedOption.target.name
    setValue(name, value || "")
    setSelectValue((oldState: any) => { return { ...oldState, [name]: value || "" } })
  }
  useEffect(() => {
    register({ name: "status" })
    register({ name: "category" })
    register({ name: "serie" })
    register({ name: "transportation" })
    register({ name: "offerPrice" })
    register({ name: "location" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register])

  const [location, setLocation] = useState('')
  const handleLocationChange = (location: string) => {
    setValue('location', location)
    setLocation(location)
  }

  //load initial values 
  const setValues = (obj: { [key: string]: string }) => {
    Object.keys(obj).forEach(itemkey => {
      setValue(itemkey, obj[itemkey])
    })
  }



  const path = `/products/${match && match.params.id}`
  const [snapshot, error] = useDocument(firestore.doc(path))


  const id = match && match.params.id

  useEffect(() => {
    const data = snapshot && snapshot.data()
    if (data) {
      const selData = { category: data.category || "Kies categorie", serie: data.serie || "Kies serie", status: data.status || "niet verkocht", transportation: data.transportation || false, offerPrice: data.offerPrice || false }
      //console.log(data)
      setValues(data)
      setValues(selData)
      setLocation(data.location)
      //console.log(data.transportation, "data.transportation")
      setSelectValue(selData)
      setPhotos(data["photos"] || [])
      setFiles(data["files"] || [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapshot])


  const removeFile = async (fullPath: string, field: "files" | "photos") => {

    //@ts-ignore
    //const orgData = (snapshot && snapshot.data() && snapshot.data()[field]) || []
    //@ts-ignore
    //const data = 

    console.log(fullPath, "fullPath")

    field === "photos" ? setPhotos((orgValues) => (orgValues.filter((item: any) => item.fullPath !== fullPath))) : setFiles((orgValues) => (orgValues.filter((item: any) => item.fullPath !== fullPath)))

    //await firestore.doc(path).update({ [field]: data })
    const storageRef = storage.ref(fullPath)
    await storageRef.delete()
  }


  //fileupload
  const fileUpload = (e: any, field: "files" | "photos") => {
    try {
      const file = e.target.files[0]
      const fileNameOrg = file.name
      const storageRef = storage.ref()
      const filename = uuidv1()
      const extention = file.name.split('.').slice(-1)
      const fullName = `${filename}.${extention}`

      const ref = storageRef.child(fullName)
      ref.put(file).then(function (filedata) {
        ref.getDownloadURL().then(async function (url) {
          //@ts-ignore
          //const orgData = (snapshot && snapshot.data() && snapshot.data()[field]) || []
          //@ts-ignore
          //const data = orgData && orgData.concat({ fullPath: filedata.metadata.fullPath, fileName: fileNameOrg, url: url })
          //await firestore.doc(path).update({ [field]: data })
          //@ts-ignore
          field === "photos" ? setPhotos((orgValues) => ([...orgValues, { fullPath: filedata.metadata.fullPath, fileName: fileNameOrg, url: url }])) :
            //@ts-ignore
            setFiles((orgValues) => ([...orgValues, { fullPath: filedata.metadata.fullPath, fileName: fileNameOrg, url: url }]))
        })
      })

    }
    catch (error) {
      console.log(error)
    }
  }



  return (
    <AdminTemplate
      adminNavmenu={<SideNavigation sideNavigationSelectedValue={"Producten"} sideNavigationOnClick={(menu) => history && history.push(menu)} />}
      contentFullSize
    >

      <ProductSwitch
        location={location}
        onLocationChange={handleLocationChange}
        onSelectChange={handleMultiChange}
        selectValues={selectedValues}
        selectedValue={mySelected}
        regRef={register}
        errors={errors}
        path={path}
        topNavigationChanged={(selected) => {
          setSelected(selected)
        }
        }
        onGlobalSaveClick={
          async () => {
            console.log(photos, "photos")
            const validated = await triggerValidation()
            if (validated === true) {
              //console.log(validated, "form validation result", watch())
              const userName = (value && value.docs && value.docs[0] && value.docs[0].data() && value.docs[0].data().familyname) || ""
              const firstName = (value && value.docs && value.docs[0] && value.docs[0].data() && value.docs[0].data().firstname) || ""
              const email = (value && value.docs && value.docs[0] && value.docs[0].data() && value.docs[0].data().email) || ""

              const data = { ...watch(), "photos": photos, "files": files, "ownerLastName": userName, "ownerFirstName": firstName, "ownerEmail": email }
              console.log(path, "path")
              if (path !== "/products/new") {
                firestore.doc(path).update(data)
              } else {
                const newProductData: ProductModel = { creationDate: new Date(), userId: user && user.uid, ...data }
                console.log(newProductData)
                await firestore.collection("products").add(newProductData)
                history && history.push("/products")
              }
            } else {
              console.log(errors)
              setDialog(true)
            }
          }
        }
        //files
        //file upload
        onFileUpload={(e) => fileUpload(e, "files")}
        //@ts-ignore
        otherfiles={files}
        onFileDelete={(url) => removeFile(url, "files")}
        //photo
        onPhotoUpload={(e) => fileUpload(e, "photos")}
        //@ts-ignore
        //photos={(snapshot && snapshot.data() && snapshot.data()["photos"]) || []}
        photos={photos}
        onPhotoDelete={(url) => removeFile(url, "photos")}
        id={id || undefined}
        canDelete={userData.admin && !error && snapshot && snapshot.exists}
        onGlobalDeleteClick={() => {
          confirm("Product verwijderen ?").then(async (resOk) => {
            await firestore.doc(path).delete()
            history && history.push('/products')
          },
            closeRes => { })
        }
        }
      />


      <DialogLabelButton
        open={dialog}
        dialogButton
        fullWidth
        onCloseDialog={() =>
          setDialog(false)
        }
        onSaveDialog={() => console.log("Saved")}
      >

        {errors !== undefined && Object.keys(errors).length > 0 &&
          //@ts-ignore
          Object.keys(errors).map(key => (errors[key].message)).map(itemM => (
            <Label labelSize="xsmall" key={itemM}>{itemM}</Label>
          ))
        }
      </DialogLabelButton>


    </AdminTemplate>
  )
}
export default ProductPage