import React from 'react';
import { FileInput, FileInputProps } from '../../atoms/FileInput';
import { Label, LabelProps } from '../../atoms/Label';


export interface InputImageProps extends FileInputProps, LabelProps {
    inputImageTitle?: string;
    inputImageStyle?: any;
}

const InputImage = ({ inputImageStyle, inputImageTitle, ...props }: InputImageProps) => {
    return (
        <div style={inputImageStyle}>
            <Label>{inputImageTitle}</Label>
            <FileInput {...props} />
        </div>
    )
};

export { InputImage };
