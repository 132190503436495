import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { confirmable } from 'react-confirm';


interface ConfirmDialogProps {
    show: boolean;
    proceed: () => any;
    dismiss: () => any;
    cancel: () => any;
    confirmation: string;
}

const ConfirmDialog = ({ show, proceed, dismiss, cancel, confirmation }: ConfirmDialogProps) => {
    return (
        <Dialog onClose={dismiss} open={show} maxWidth={"sm"} fullWidth>
            <DialogTitle id="alert-dialog-title">{"Confirmatie"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {confirmation}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => cancel()} color="primary">
                    Sluiten
            </Button>
                <Button onClick={() => proceed()} color="primary" autoFocus>
                    OK
          </Button>
            </DialogActions>
        </Dialog>
    )
}


export default confirmable(ConfirmDialog);