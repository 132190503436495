import React from 'react';
import { palette } from '../../themes/index';


export interface FileInputProps {
  onlyImage?: boolean;
  fileInputName?: string;
  inputOnChange?: (e: any) => any;
  inputFileStyle?: any;
  fileUploadTitle?: string;
}

const FileInput = ({ fileUploadTitle, fileInputName, onlyImage, inputOnChange, inputFileStyle }: FileInputProps) => {
  const acceptOnly = onlyImage ? "image/x-png, image/jpeg" : "application/pdf"
  return (
    <div style={inputFileStyle}>
      <label htmlFor={fileInputName} style={{ width: 20, cursor: 'pointer' }}>
        <span style={{ padding: 10, background: palette.grey, color: palette.white, textTransform: 'uppercase', letterSpacing: '3px' }} className="fileLabel" >{fileUploadTitle}</span>
        <input type="file" id={fileInputName} accept={acceptOnly} name={fileInputName} onChange={inputOnChange} style={{ display: 'none' }} />
      </label>
    </div>
  )
}

export { FileInput };

