import { useEffect, useRef, useState } from 'react'

export interface Marker {
  icon?: string | {
    anchor: any
    origin: any
    scaledSize?: any
    size?: any
    url: string
  }
  position: {
    lat: number
    lng: number
  }
  title?: string
}

export interface MapProps {
  mapMarkers?: Marker[]
  mapDefaultLat?: number
  mapDefaultLng?: number
  mapDefaultZoom?: number
}


const Map = ({ mapMarkers, mapDefaultLat, mapDefaultLng, mapDefaultZoom }: MapProps) => {
  const mapDiv = useRef<HTMLDivElement | null>(null)
  const currentMapDiv = mapDiv.current
  const [map, setMap] = useState<google.maps.Map>()
  const displayedMarkers = useRef<Array<google.maps.Marker>>([])

  useEffect(() => {
    if (currentMapDiv) {
      const newMap = new google.maps.Map(currentMapDiv, {
        center: { lat: mapDefaultLat ? mapDefaultLat : 51.477143, lng: mapDefaultLng ? mapDefaultLng : 4.335803 },
        zoom: mapDefaultZoom,
        styles: [
          {
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#f5f5f5"
              }
            ]
          },
          {
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#f5f5f5"
              }
            ]
          },
          {
            "featureType": "administrative",
            "elementType": "geometry",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "administrative.land_parcel",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#bdbdbd"
              }
            ]
          },
          {
            "featureType": "administrative.neighborhood",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#eeeeee"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#e5e5e5"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#ffffff"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#dadada"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          },
          {
            "featureType": "transit",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#e5e5e5"
              }
            ]
          },
          {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#eeeeee"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#c9c9c9"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          }
        ],
      })
      setMap(newMap)
    }
  }, [currentMapDiv, mapDefaultLat, mapDefaultLng, mapDefaultZoom])
  useEffect(() => {
    if (map && mapMarkers) {
      displayedMarkers.current.forEach(marker => marker.setMap(null))
      displayedMarkers.current = mapMarkers.map((marker) => {
        const googleMarker = new google.maps.Marker({
          ...marker,
          map,
        })
        return googleMarker
      })

    }
  }, [map, mapMarkers])
  return (
    <div style={{ width: '100%', height: '40vh' }} ref={mapDiv} />
  )
}

export default Map
