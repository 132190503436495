import { History } from 'history'
import React, { useState } from 'react'
import useForm from 'react-hook-form'
import * as yup from 'yup'
import { auth } from '../../../utils/firebase'
import { LoginForm, LoginFormDataScheme } from '../../organisms/LoginForm'
import NoUiTemplate from '../../templates/NoUiTemplate'
import { palette } from '../../themes'

interface LoginPageProps {
  history?: History
}

const LoginValidationScheme = yup.object().shape({
  username: yup.string().required("veld is verplicht").email("veld moet email zijn"),
  password: yup.string().required("veld is verplicht"),
})


const LoginPage = ({ history }: LoginPageProps) => {

  const { register, watch, errors, triggerValidation } = useForm({
    mode: "onBlur",
    validationSchema: LoginValidationScheme
  })

  const [authError, setAuthError] = useState(undefined)

  const login = async () => {
    try {
      const { username, password } = watch() as LoginFormDataScheme
      if (username && password) {
        await auth.signInWithEmailAndPassword(username, password)
        setAuthError(undefined)
        history && history.push("/products")
      } else {
        throw new Error("Error")
      }
    } catch (err: any) {
      setAuthError(err["message"] || "unknown")
    }
  }

  return (
    <NoUiTemplate>
      {authError &&
        <div><p style={{ color: palette.danger }}>Error: {authError}</p></div>
      }
      <LoginForm
        onRegisterClick={() => history && history.push("/register")}
        onLoginClick={async () => {
          const validated = await triggerValidation()
          if (validated === true) {
            login()
          }
        }}
        onRecoveryClick={(e: any) => {
          e.preventDefault()
          history && history.push("/recover")
        }}
        regRef={register}
        errors={errors}
      />
    </NoUiTemplate >
  )
}

export default LoginPage