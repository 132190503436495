import { default as MTableRow } from '@material-ui/core/TableRow';
import React from 'react';

export interface TableRowProps {
  className?: string;
  tableRowOnClick?: (e: any) => any
  children?: any
}


const TableRow = ({ children, tableRowOnClick, className }: TableRowProps) => {
  return (<MTableRow className={className} onClick={tableRowOnClick}>{children}</MTableRow>)
};

export { TableRow };
