import React, { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import EditProfilePage from './components/pages/EditProfilePage';
import LoginPage from './components/pages/LoginPage';
import NotFoundPage from './components/pages/NotFoundPage';
import PoductListPage from './components/pages/ProductListPage';
import ProductPage from './components/pages/ProductPage';
import RecoveryPage from './components/pages/RecoveryPage';
import RegistrationPage from './components/pages/RegistrationPage';
import { UserProvider } from './utils/context';
import { auth } from './utils/firebase';
import { ProtectedRoute } from './utils/routing';


const App: React.FC = () => {

  const [user, loading] = useAuthState(auth)
  const userAuthificated = !user && loading === false ? false : true
  const [admin, setAdmin] = useState(false)

  user && user.getIdTokenResult()
    .then((idTokenResult) => {
      if (!!idTokenResult.claims.admin) {
        setAdmin(true)
      } else {
        setAdmin(false)
      }
    })
    .catch((error) => {
      console.log(error);
    });


  return (
    <UserProvider value={{ admin: admin }}>
      <BrowserRouter>
        <Switch>
          <Route exact path={"/"} render={() => <Redirect to={"/products"} />} />
          <Route exact path={"/login"} component={LoginPage} />
          <Route exact path={"/register"} component={RegistrationPage} />
          <Route exact path={"/recover"} component={RecoveryPage} />
          <ProtectedRoute authificated={userAuthificated} path="/account" comp={EditProfilePage} />
          <ProtectedRoute authificated={userAuthificated} path="/products/:id" comp={ProductPage} />
          <ProtectedRoute authificated={userAuthificated} path="/products" comp={PoductListPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    </UserProvider>
  )

}

export default App;
