import { palette } from '../../themes/index'
import React from 'react';
import { default as MIcon } from '@material-ui/core/Icon';



export interface IconProps {
  iconColor?: string;
  iconName?: "3d_rotation" | 'ac_unit' | 'access_alarm' | 'access_alarms' | 'access_time' | 'accessibility' | 'accessible' |
  'account_balance' | 'account_balance_wallet' | 'account_box' | 'account_circle' | 'adb' | 'add' | 'add_a_photo' | 'add_alarm' |
  'add_alert' | 'add_box' | 'add_circle' | 'add_circle_outline' | 'add_location' | 'add_shopping_cart' | 'add_to_photos' |
  'add_to_queue' | 'adjust' | 'airline_seat_flat' | 'airline_seat_flat_angled' | 'airline_seat_individual_suite' | 'airline_seat_legroom_extra' |
  'airline_seat_legroom_normal' | 'airline_seat_legroom_reduced' | 'airline_seat_recline_extra' | 'airline_seat_recline_normal' |
  'airplanemode_active' | 'airplanemode_inactive' | 'airplay' | 'airport_shuttle' | 'alarm' | 'alarm_add' | 'alarm_off' |
  'alarm_on' | 'album' | 'all_inclusive' | 'all_out' | 'android' | 'announcement' | 'apps' | 'archive' | 'arrow_back' |
  'arrow_downward' | 'arrow_drop_down' | 'arrow_drop_down_circle' | 'arrow_drop_up' | 'arrow_forward' | 'arrow_upward' |
  'art_track' | 'aspect_ratio' | 'assessment' | 'assignment' | 'assignment_ind' | 'assignment_late' | 'assignment_return' |
  'assignment_turned_in' | 'assistant' | 'assistant_photo' | 'attach_file' | 'attach_money' | 'attachment' | 'audiotrack' |
  'autorenew' | 'av_timer' | 'backspace' | 'backup' | 'battery_alert' | 'battery_charging_full' | 'battery_full' |
  'battery_std' | 'battery_unknown' | 'beach_access' | 'beenhere' | 'block' | 'bluetooth' | 'bluetooth_audio' |
  'bluetooth_connected' | 'bluetooth_disabled' | 'bluetooth_searching' | 'blur_circular' | 'blur_linear' | 'blur_off' | 'blur_on' |
  'book' | 'bookmark' | 'bookmark_border' | 'border_all' | 'border_bottom' | 'border_clear' | 'border_color' | 'border_horizontal' |
  'border_inner' | 'border_left' | 'border_outer' | 'border_right' | 'border_style' | 'border_top' | 'border_vertical' |
  'branding_watermark' | 'brightness_1' | 'brightness_2' | 'brightness_3' | 'brightness_4' | 'brightness_5' | 'brightness_6' |
  'brightness_7' | 'brightness_auto' | 'brightness_high' | 'brightness_low' | 'brightness_medium' | 'broken_image' |
  'brush' | 'bubble_chart' | 'bug_report' | 'build' | 'burst_mode' | 'business' | 'business_center' | 'cached' | 'cake' |
  'call' | 'call_end' | 'call_made' | 'call_merge' | 'call_missed' | 'call_missed_outgoing' | 'call_received' | 'call_split' |
  'call_to_action' | 'camera' | 'camera_alt' | 'camera_enhance' | 'camera_front' | 'camera_rear' | 'camera_roll' |
  'cancel' | 'card_giftcard' | 'card_membership' | 'card_travel' | 'casino' | 'cast' | 'cast_connected' | 'center_focus_strong' |
  'center_focus_weak' | 'change_history' | 'chat' | 'chat_bubble' | 'chat_bubble_outline' | 'check' | 'check_box' | 'check_box_outline_blank' |
  'check_circle' | 'chevron_left' | 'chevron_right' | 'child_care' | 'child_friendly' | 'chrome_reader_mode' | 'class' |
  'clear' | 'clear_all' | 'close' | 'closed_caption' | 'cloud' | 'cloud_circle' | 'cloud_done' | 'cloud_download' |
  'cloud_off' | 'cloud_queue' | 'cloud_upload' | 'code' | 'collections' | 'collections_bookmark' | 'color_lens' | 'colorize' |
  'comment' | 'compare' | 'compare_arrows' | 'computer' | 'confirmation_number' | 'contact_mail' | 'contact_phone' | 'contacts' |
  'content_copy' | 'content_cut' | 'content_paste' | 'control_point' | 'control_point_duplicate' | 'copyright' | 'create' | 'create_new_folder' |
  'create_new_folder' | 'credit_card' | 'crop' | 'crop_16_9' | 'crop_3_2' | 'crop_5_4' | 'crop_7_5' | 'crop_din' |
  'crop_free' | 'crop_landscape' | 'crop_original' | 'crop_portrait' | 'crop_rotate' | 'crop_square' | 'dashboard' | 'data_usage' |
  'date_range' | 'dehaze' | 'delete' | 'delete_forever' | 'delete_sweep' | 'description' | 'desktop_mac' | 'desktop_windows' |
  'details' | 'developer_board' | 'developer_mode' | 'device_hub' | 'devices' | 'devices_other' |
  'dialer_sip' | 'dialpad' | 'directions' | 'directions_bike' | 'directions_boat' | 'directions_bus' |
  'directions_car' | 'directions_railway' | 'directions_run' | 'directions_subway' | 'directions_transit' |
  'directions_walk' | 'disc_full' | 'dns' | 'do_not_disturb' | 'do_not_disturb_alt' |
  'do_not_disturb_off' | 'do_not_disturb_on' | 'dock' | 'domain' | 'done' | 'done_all' |
  'donut_large' | 'donut_small' | 'drafts' | 'drag_handle' | 'drive_eta' | 'dvr' | 'edit' |
  'edit_location' | 'eject' | 'email' | 'enhanced_encryption' | 'equalizer' | 'error' | 'error_outline' |
  'euro_symbol' | 'ev_station' | 'event' | 'event_available' | 'event_busy' | 'event_note' |
  'event_seat' | 'exit_to_app' | 'expand_less' | 'expand_more' | 'explicit' | 'explore' |
  'exposure' | 'exposure_neg_1' | 'exposure_neg_2' | 'exposure_plus_1' | 'exposure_plus_2' |
  'exposure_zero' | 'extension' | 'face' | 'fast_forward' | 'fast_rewind' | 'favorite' |
  'favorite_border' | 'featured_play_list' | 'featured_video' | 'feedback' | 'fiber_dvr' |
  'fiber_manual_record' | 'fiber_new' | 'fiber_pin' | 'fiber_smart_record' | 'file_download' | 'file_upload' |
  'filter' | 'filter_1' | 'filter_2' | 'filter_3' | 'filter_4' | 'filter_5' | 'filter_6' | 'filter_7' |
  'filter_8' | 'filter_9' | 'filter_9_plus' | 'filter_b_and_w' | 'filter_center_focus' | 'filter_drama' |
  'filter_frames' | 'filter_hdr' | 'filter_list' | 'filter_none' | 'filter_tilt_shift' |
  'filter_vintage' | 'find_in_page' | 'find_replace' | 'fingerprint' | 'first_page' | 'fitness_center' | 'flag' |
  'flare' | 'flash_auto' | 'flash_off' | 'flash_on' | 'flight' | 'flight_land' | 'flight_takeoff' |
  'flip' | 'flip_to_back' | 'flip_to_front' | 'folder' | 'folder_open' | 'folder_shared' |
  'folder_special' | 'font_download' | 'format_align_center' | 'format_align_justify' |
  'format_align_left' | 'format_align_right' | 'format_bold' | 'format_clear' | 'format_color_fill' |
  'format_color_reset' | 'format_color_text' | 'format_indent_decrease' | 'format_indent_increase' |
  'format_italic' | 'format_line_spacing' | 'format_list_bulleted' | 'format_list_numbered' | 'format_paint' |
  'format_quote' | 'format_shapes' | 'format_size' | 'format_strikethrough' | 'format_textdirection_l_to_r' |
  'format_textdirection_r_to_l' | 'format_underlined' | 'forum' | 'forward' | 'forward_10' |
  'forward_30' | 'forward_5' | 'free_breakfast' | 'fullscreen' | 'fullscreen_exit' | 'functions' |
  'home' | 'image' | 'info' | 'input' | 'label' | 'map' | 'message' | 'save' | 'send' | 'star';

  iconSize?: "small" | "normal" | "large" | "xlarge" | "xxlarge" | "xxxlarge"
  iconStyle?: any;
}


const Icon = ({ iconColor, iconName, iconSize, iconStyle }: IconProps) => {

  const iconSizes = {
    small: 16,
    normal: 22,
    large: 28,
    xlarge: 32,
    xxlarge: 48,
    xxxlarge: 64
  }

  const color = iconColor ? iconColor : palette.black
  const size = iconSize ? iconSizes[iconSize] : iconSizes.normal

  return (
    <div>
      <MIcon style={{ color: color, fontSize: size, ...iconStyle }}>
        {iconName}
      </MIcon>
    </div>


  )
};

export { Icon }