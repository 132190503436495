
import React from 'react';
import { Button, ButtonProps } from '../../atoms/Button';
import { Icon, IconProps } from '../../atoms/Icon';
import { palette } from '../../themes';

export interface IconButtonProps extends ButtonProps, IconProps {
    iconButtonText?: string;
    iconButtonStyle?: any;
}

const IconButton = ({ iconButtonText, iconName, iconButtonStyle, ...props }: IconButtonProps) => {
    const { buttonPrimary, buttonTextColor } = props;
    return (
        <div style={iconButtonStyle}>
            <Button  {...props} > <Icon iconName={iconName} iconStyle={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flexDirection: 'column',
                color: buttonTextColor ? buttonTextColor : buttonPrimary ? palette.white : palette.black,
            }} {...props} /><span style={{ paddingLeft: iconName && iconButtonText ? '5px' : '0px' }}>{iconButtonText}</span></Button>
        </div>
    )
};

export { IconButton };
