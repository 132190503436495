import React from 'react';
import { ProductModel } from '../../../models/products';
import { Col } from '../../atoms/Col';
import { Dropdown } from '../../atoms/Dropdown';
import { Label } from '../../atoms/Label';
import { Row } from '../../atoms/Row';
import { SearchInput } from '../../atoms/SearchInput';
import { Space } from '../../atoms/Space';
import { Table } from '../../atoms/Table';
import { TableBody } from '../../atoms/TableBody';
import { TableCell } from '../../atoms/TableCell';
import { TableHead } from '../../atoms/TableHead';
import { TableRow } from '../../atoms/TableRow';
import { IconButton } from '../../molecules/IconButton';


interface extraProp extends ProductModel {
    id?: string;
}


export interface ProductListProps {
    productOnAddClick?: (e: any) => any;
    productEditOnclick?: (e: any) => any;
    rows?: extraProp[],
    onSearchInputChange?: (e: any) => any;
    searchInputValue?: string;
    onFilterChange?: (e: any) => any;
    filterValue?: string
}


const ProductList = ({ filterValue, onFilterChange, searchInputValue, onSearchInputChange, rows, productOnAddClick, productEditOnclick }: ProductListProps) => {

    return (
        <Row>
            <Col colSize="col-xs-12 end-xs">
                <Space />
                <IconButton buttonPrimary iconName="add" onClick={productOnAddClick} iconButtonText={"Nieuw Product"} />
            </Col>

            <Col colSize="col-xs-6 start-xs">
                <SearchInput inputType="text" inputLabel="Search" inputDefaultValue={searchInputValue || ""} inputOnChange={(e: any) => onSearchInputChange && onSearchInputChange(e)} />
                <Space />
            </Col>

            <Col colSize="col-xs-6 end-xs">
                <Dropdown dropdownDisplayEmpty={false} dropdownDefaultValue={filterValue} dropdownOnChange={(e) => onFilterChange && onFilterChange(e)} dropdownLabel="Status" dropdownRecords={[{ name: "Beschikbaar/niet-beschikbaar", value: "" }, { name: "Beschikbaar", value: "niet verkocht" }, { name: "niet-beschikbaar", value: "verkocht" }]} />
                <Space />
            </Col>

            <Col colSize="col-xs-12" colStyle={{ overflow: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow >
                            <TableCell align="center"><Label labelBold labelSize="xsmall" labelUppercase>Artikelnaam</Label></TableCell>
                            <TableCell align="center"><Label labelBold labelSize="xsmall" labelUppercase>Categorie</Label></TableCell>
                            <TableCell align="center"><Label labelBold labelSize="xsmall" labelUppercase>Serie</Label></TableCell>
                            <TableCell align="center"><Label labelBold labelSize="xsmall" labelUppercase>Hoeveelheid</Label></TableCell>
                            <TableCell align="center"><Label labelBold labelSize="xsmall" labelUppercase>Prijs/stuk</Label></TableCell>
                            <TableCell align="center"><Label labelBold labelSize="xsmall" labelUppercase>Status</Label></TableCell>
                            <TableCell align="center"><Label labelBold labelSize="xsmall" labelUppercase>Aanvragen</Label></TableCell>
                            <TableCell align="center"><Label labelBold labelSize="xsmall" labelUppercase>Beschikbaar</Label></TableCell>
                        </TableRow>

                    </TableHead>

                    <TableBody>
                        {rows && rows.map((doc, index) =>
                            (
                                <TableRow className="hoverRow" tableRowOnClick={() => productEditOnclick && productEditOnclick(doc && doc.id)} key={doc.id}>
                                    <TableCell align="center">{(doc && doc.articleName) || ""}</TableCell>
                                    <TableCell align="center">{(doc && doc.category) || ""}</TableCell>
                                    <TableCell align="center">{(doc && doc.serie) || ""}</TableCell>
                                    <TableCell align="center">{(doc && doc.quantity) || ""}</TableCell>
                                    <TableCell align="center">{(doc && doc.pricePerPiece) || ""}</TableCell>
                                    <TableCell align="center">{(doc && doc.status && doc.status === "verkocht" ? "niet-beschikbaar" : "Beschikbaar") || ""}</TableCell>
                                    <TableCell align="center">{(doc && doc.requestCount) || "0"}</TableCell>
                                    <TableCell align="center">{(doc && doc.availableFrom) || ""}</TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </Col>

        </Row>

    )
}


export { ProductList };

