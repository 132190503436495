
import React from 'react'
// import { CopyrightBlock } from '../../organisms/CopyrightBlock';
import { palette } from '../../themes/index'
import { Row } from '../../atoms/Row';
import { Col } from '../../atoms/Col';

export interface SingleProps {
  singleTitle?: JSX.Element,
  singleFooter?: JSX.Element,
  storybook?: boolean;
  children?: any,
}

const SingleTemplate = ({ singleTitle, singleFooter, storybook, children, ...props
}: SingleProps) => {
  return (

    <Row rowAlignItemsCenter rowNoMargin>
      {/* NAVIGATIE */}
      <Col colSize="col-xs-12 center-xs" colStyle={{ background: storybook ? palette.secondary : "" }}>

        <Row rowAlignItemsCenter rowStyle={{ minHeight: '10vh' }}>
          <Col colSize="col-xs-12 center-xs">
            {singleTitle || "Title"}
          </Col>
        </Row>

      </Col>

      <Col colSize="col-xs-12 center-xs" colStyle={{ background: storybook ? palette.primary : "" }}>
        <Row rowAlignItemsCenter rowStyle={{ minHeight: '80vh' }}>
          <Col colSize="col-xs-12 center-xs">
            {children || "Body"}
          </Col>
        </Row>
      </Col>



      <Col colSize="col-xs-12 end-xs" colStyle={{ background: storybook ? palette.primary : "" }}>
        <Row rowAlignItemsCenter rowStyle={{ minHeight: '10vh' }}>
          <Col colSize="col-xs-12 center-xs">
            {singleFooter || "Footer"}
          </Col>
        </Row>
      </Col>


    </Row>
  )
}

export default SingleTemplate
