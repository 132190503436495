import React from 'react';
import { Button, ButtonProps } from '../../atoms/Button';
import { Label, LabelProps } from '../../atoms/Label';
import { palette } from '../../themes/index';

export interface NavigationItemProps extends ButtonProps, LabelProps {
    navigationText?: string;
    selected?: boolean;

}


const TopNavigationItem = ({ navigationText, selected, ...props }: NavigationItemProps) => {
    return (
        <Button buttonTextExtraSpaceUnit="50px" {...props} buttonStyle={{ backgroundColor: selected ? palette.lightBlue : palette.secondary, margin: 6 }}>
            <Label labelSize="small" labelUppercase>{navigationText}</Label>
        </Button>
    )
}

export { TopNavigationItem };

