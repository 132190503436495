import React from 'react'
import { Col } from '../../atoms/Col'
import { Image } from '../../atoms/Image'
import { Row } from '../../atoms/Row'
import { palette, sizes } from '../../themes/index'


export interface ProductTemplateProps {
  adminNavmenu?: JSX.Element,
  storybook?: boolean,
  children?: any
  contentFullSize?: boolean
  contentCenter?: boolean
  title?: JSX.Element
}
const AdminTemplate = ({ title, contentCenter, contentFullSize, adminNavmenu, storybook, children, ...props }: ProductTemplateProps) => {

  const curSize = contentFullSize ? "col-xs-12" : "col-xs-10"
  const center = contentCenter ? "center-xs" : ""
  const contentColSize = `${curSize} ${center}`

  return (
    <Row rowNoMargin rowStyle={{
      minHeight: '100vh'
    }}>

      {/* NAVIGATIE */}
      {adminNavmenu &&
        <Col colSize="col-xs-2" colStyle={{ background: storybook ? palette.secondary : palette.secondary }}>
          <Row rowStyle={{ marginRight: 15 }} >
            <Col colSize="col-xs-12">
              <Image imageSrc='/logo.jpg' imageWidth="100%" imageAlt="logo" imageStyle={{ paddingTop: 10, paddingLeft: 10, marginRight: 5 }} />
            </Col>
            <Col colSize="col-xs-12 center-xs">
              <a href="/" onClick={(e) => {
                e.preventDefault()
                const _host = (window && window.location && window.location.hostname) || undefined
                const _newHost = _host !== undefined && (_host.indexOf("oogstadmin") > 0) ? _host.replace("oogstadmin", "oogstkaart") : undefined
                if (_newHost !== undefined) {
                  window.location.href = `https://${_newHost}`
                }
              }} style={{ textDecoration: 'none' }}>Terug naar Oogstkaart</a>
            </Col>
          </Row>
          <div style={{ paddingTop: sizes.fieldPadding }}>
            {adminNavmenu || "Nav Menu"}
          </div>
        </Col>
      }


      <Col colSize="col-xs-10" colStyle={{ background: storybook ? palette.alert : "" }}>
        {/* TITLE */}
        {title &&
          <Row rowAlignItemsCenter rowStyle={{
            background: storybook ? palette.primary : "", minHeight: '10vh'
          }}>

            <Col colSize="col-xs-12 center-xs" colNoHorizontalPadding>
              {title || ""}
            </Col>
          </Row>
        }



        {/* BODY */}
        <Row rowOptions={"center-xs"} rowAlignItemsCenter={contentCenter ? true : false} rowStyle={{
          background: storybook ? palette.primary : "",
          minHeight: '100vh',
        }}>

          {/* CONTENT */}
          <Col colSize={contentColSize}>
            {children || ""}
          </Col>
        </Row>
      </Col>

    </Row >
  )
}

export default AdminTemplate