import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useCallback, useEffect, useMemo, useState } from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { Label } from '../../atoms/Label'
import Map from '../../atoms/Map/Map'
import { palette } from '../../themes'

export interface AddressAutocompleteInputProps {
    error?: string
    label?: string
    name?: string
    onChange?: (value: string) => unknown
    regRef?: any
    required?: boolean
    value?: string
}

const AddressAutocompleteInput = ({ error, label, name, onChange, regRef, required, value }: AddressAutocompleteInputProps) => {
    const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>()
    const [latLng, setLatLng] = useState<google.maps.LatLngLiteral>()
    const mapMarkers = useMemo(() => latLng ? [{ position: latLng }] : [], [latLng])
    const searchOptions = useMemo(() => ({
        location: new window.google.maps.LatLng(51.477143, 4.335803),
        radius: 150000,
        sessionToken,
        types: ['address'],
    }), [sessionToken])
    const handleSelect = useCallback((address: string) => {
        setSessionToken(undefined)
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(setLatLng)
            .catch(error => console.error('Error', error))
    }, [])
    useEffect(() => {
        if (!latLng && value) {
            handleSelect(value)
        }
    }, [handleSelect, latLng, value])
    const handleChange = useCallback((test: string) => {
        onChange?.(test)
        if (!sessionToken) {
            setSessionToken(new window.google.maps.places.AutocompleteSessionToken())
        }
    }, [onChange, sessionToken])
    return <PlacesAutocomplete
        value={value}
        onChange={handleChange}
        onSelect={handleSelect}
        //@ts-ignore https://github.com/DefinitelyTyped/DefinitelyTyped/pull/41295
        searchOptions={searchOptions}
    >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
            const inputProps = getInputProps()
            return (
                <Autocomplete
                    filterOptions={x => x}
                    freeSolo
                    getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
                    loading={loading}
                    options={[...suggestions]}
                    value={inputProps.value}
                    renderInput={params => (
                        <div>
                            <TextField
                                {...inputProps}
                                {...params}
                                fullWidth
                                inputRef={regRef}
                                label={label}
                                name={name}
                                required={required}
                            />
                            <Label labelStyle={{ color: palette.danger, fontSize: 12, textAlign: 'left', paddingBottom: '8px' }}>
                                <span>{error || ''}</span>
                            </Label>
                            <Map
                                mapDefaultLat={51.477143}
                                mapDefaultLng={4.335803}
                                mapDefaultZoom={6}
                                mapMarkers={mapMarkers}
                            />
                        </div>
                    )}
                    renderOption={option => {

                        return (
                            <Grid {...getSuggestionItemProps(option)} container alignItems="center" >
                                <Grid item>
                                    <LocationOnIcon />
                                </Grid>
                                <Grid item xs>
                                    <span style={{ fontWeight: 400 }}>
                                        {option.description}
                                    </span>
                                </Grid>
                            </Grid>
                        )
                    }}
                />

            )
        }
        }
    </PlacesAutocomplete>
}

export default AddressAutocompleteInput
