import React, { useEffect, useState } from 'react';
import { Col } from '../../atoms/Col';
import { SideMenu } from '../../molecules/SideMenu';



export interface SideNavigationProps {
    sideNavigationChanged?: any;
    sideNavigationSelectedValue?: any;
    sideNavigationOnClick?: (route: string) => any
}

const SideNavigation = ({ sideNavigationOnClick, sideNavigationChanged, sideNavigationSelectedValue, ...props }: SideNavigationProps) => {
    const [mySelected, setSelected] = useState()



    useEffect(() => {
        sideNavigationSelectedValue && setSelected(sideNavigationSelectedValue)
    }, [sideNavigationSelectedValue])

    return (
        <Col colSize=" col-xs-12">
            <SideMenu navigationText={"Producten"} onClick={() => sideNavigationOnClick && sideNavigationOnClick("/products")} selected={mySelected === "Producten" ? true : false} />
            <SideMenu navigationText={"Account"} onClick={() => sideNavigationOnClick && sideNavigationOnClick("/account")} selected={mySelected === "Account" ? true : false} />
        </Col>
    )
}

export { SideNavigation };

