import React, { useState, useEffect } from 'react';
import { TopNavigationItem } from '../../molecules/TopNavigationItem';

export interface TopNavigationProps {
    topNavigationChanged?: (value: string) => any;
    selectedValue?: string;
}




const TopNavigation = ({ selectedValue, topNavigationChanged, ...props }: TopNavigationProps) => {
    const [mySelected, setSelected] = useState<string>();

    function navSelected(value: string) {
        setSelected(value)
        topNavigationChanged && topNavigationChanged(value)
    }

    useEffect(() => {
        selectedValue && setSelected(selectedValue)
    }, [selectedValue]
    )

    return (
        <div>
            <TopNavigationItem onClick={() => navSelected("algemene informatie")} selected={mySelected === "algemene informatie" ? true : false} navigationText='algemene informatie'  {...props} />
            <TopNavigationItem onClick={() => navSelected("specificaties")} selected={mySelected === "specificaties" ? true : false} navigationText='specificaties'  {...props} />
            <TopNavigationItem onClick={() => navSelected("fotos & files")} selected={mySelected === "fotos & files" ? true : false} navigationText="foto's  & files"  {...props} />
            <TopNavigationItem onClick={() => navSelected("aanvragen")} selected={mySelected === "aanvragen" ? true : false} navigationText='aanvragen'  {...props} />
        </div>
    )
}


export { TopNavigation }