import React from 'react';
import { sizes } from '../../themes/index';

export interface SpaceProps {
  spaceTop?: any;
  spaceBottom?: any;
  spaceRight?: any;
  spaceLeft?: any
}

const Space = ({ spaceTop, spaceBottom, spaceRight, spaceLeft }: SpaceProps) => {
  return (<div
    style={{
      paddingRight: spaceRight ? spaceRight : 0,
      paddingLeft: spaceLeft ? spaceLeft : 0,
      paddingTop: spaceTop ? spaceTop : sizes.fieldPadding,
      paddingBottom: spaceBottom ? spaceBottom : 0
    }}>
  </div>)
};

export { Space };

