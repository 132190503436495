import React from 'react';
import { Redirect, Route } from 'react-router-dom';

interface ProtectedRouteProps {
    authificated: boolean,
    path: string,
    comp: any
}

export const ProtectedRoute = ({ authificated, path, comp }: ProtectedRouteProps) => {
    if (!authificated) {
        return (
            <Redirect to={"/login"} />)
    } else {
        return (
            <Route exact path={path} component={comp} />
        )
    }
}