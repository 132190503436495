import React from 'react';
import { match } from 'react-router';
import { Col } from '../../atoms/Col';
import { Row } from '../../atoms/Row';
import { InputImage } from '../../molecules/InputImage';
import FileCard from '../FileCard';

export interface PhotosFilesProps {
    onPhotoUpload?: (e: any) => any;
    onFileUpload?: (e: any) => any;
    photos?: FileItem[];
    otherfiles?: FileItem[];
    match?: match<{ id: string }>;
    onPhotoDelete?: (photoUrl: string) => any;
    onFileDelete?: (fileUrl: string) => any;
}


export interface FileItem {
    url?: string;
    fullPath?: string;
    fileName?: string;
}


const FotosFiles = ({ onFileDelete, onPhotoDelete, onPhotoUpload, onFileUpload, photos, otherfiles, match }: PhotosFilesProps) => {
    return (
        <Row rowAlignItemsCenter rowOptions="around-xs center-xs">
            <Col colSize="col-xs-6">
                <InputImage fileInputName="foto"
                    inputOnChange={onPhotoUpload}
                    inputImageTitle="Foto's"
                    labelUppercase
                    inputFileStyle={{ padding: '20px' }}
                    inputImageStyle={{ padding: 10, marginTop: '60px' }}
                    fileUploadTitle="Kies een foto"
                    onlyImage
                />
            </Col>

            <Col colSize="col-xs-6">
                <InputImage fileInputName="file"
                    inputOnChange={onFileUpload}
                    inputImageTitle="Files"
                    labelUppercase
                    inputFileStyle={{ padding: '20px' }}
                    inputImageStyle={{ padding: 10, marginTop: '60px' }}
                    fileUploadTitle="Kies een ander bestand"
                />
            </Col>

            <Col colSize="col-xs-12 center-xs">
                <Row rowOptions="collapse-xs" >
                    <Col colSize="col-xs-6 center-xs" >
                        <FileCard imagePreview cards={photos || []} onDelete={(fullPath: string) => onPhotoDelete && fullPath && onPhotoDelete(fullPath)} />
                    </Col>

                    <Col colSize="col-xs-6 center-xs">
                        <FileCard cards={otherfiles || []} onDelete={(fullPath: string) => onFileDelete && fullPath && onFileDelete(fullPath)} />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}


export { FotosFiles };

