import React from 'react';
import { Col } from '../../atoms/Col';
import { Form } from '../../atoms/Form';
import { Row } from '../../atoms/Row';
import { Space } from '../../atoms/Space';
import { InputLabel } from '../../molecules/InputLabel';

export interface NewSpecificationsProps {
    regRef?: (params: any) => void;
    errors?: any;
}

export interface NewSpecificationsDataScheme {
    weight?: string;
    height?: string;
    color?: string;
    fireResistant?: string;
    ral?: string;
    length?: string;
    width?: string;
    material?: string;
    uValue?: string;
    glass?: string;
}

const NewSpecifications = ({ regRef, errors }: NewSpecificationsProps) => {
    return (
        <Form>
            <Row rowOptions="around-xs">
                <Col colSize="col-xs-5">
                    <InputLabel shrink name="weight" regRef={regRef} inputError={(errors && errors.weight && errors.weight.message) || ""} inputFullWidth inputType="number" inputLabel="Gewicht (kg)" />
                    <Space />
                    <InputLabel shrink name="height" regRef={regRef} inputError={(errors && errors.height && errors.height.message) || ""} inputFullWidth inputType="number" inputLabel="Hoogte(mm)" />
                    <Space />
                    <InputLabel shrink name="color" regRef={regRef} inputError={(errors && errors.color && errors.color.message) || ""} inputFullWidth inputType="text" inputLabel="kleur" />
                    <Space />
                    <InputLabel shrink name="fireResistant" regRef={regRef} inputError={(errors && errors.fireResistant && errors.fireResistant.message) || ""} inputFullWidth inputType="text" inputLabel="Brandwerend" />
                    <Space />
                    <InputLabel shrink name="ral" regRef={regRef} inputError={(errors && errors.ral && errors.ral.message) || ""} inputFullWidth inputType="text" inputLabel="RAL" />
                    <Space />
                    <Space />
                    <Space />
                    <Space />
                </Col>

                <Col colSize="col-xs-5">
                    <InputLabel shrink name="length" regRef={regRef} inputError={(errors && errors.length && errors.length.message) || ""} inputFullWidth inputType="number" inputLabel="Lengte (profiel) (mm)" />
                    <Space />
                    <InputLabel shrink name="width" regRef={regRef} inputError={(errors && errors.width && errors.width.message) || ""} inputFullWidth inputType="number" inputLabel="Breedte (mm)" />
                    <Space />
                    <InputLabel shrink name="material" regRef={regRef} inputError={(errors && errors.material && errors.material.message) || ""} inputFullWidth inputType="text" inputLabel="Materiaal" />
                    <Space />
                    <InputLabel shrink name="uValue" regRef={regRef} inputError={(errors && errors.uValue && errors.uValue.message) || ""} inputFullWidth inputType="text" inputLabel="U-waarde" />
                    <Space />
                    <InputLabel shrink name="glass" regRef={regRef} inputError={(errors && errors.glass && errors.glass.message) || ""} inputFullWidth inputType="text" inputLabel="Samenstelling glas" />
                    <Space />
                    <Space />
                </Col>
            </Row>
        </Form>
    )
}

export { NewSpecifications };

