
import React from 'react';
import { Checkbox, CheckboxProps } from '../../atoms/Checkbox';
import { Col } from '../../atoms/Col';
import { Label, LabelProps } from '../../atoms/Label';
import { Row } from '../../atoms/Row';

export interface CheckboxLabelProps extends CheckboxProps, LabelProps {
    checkboxLabelText?: string;
    checkboxLabelTitle?: string;
    checkboxLabelStyle?: any;
    checkboxOnClick?: () => any;
    checkboxChanged?: (value: boolean) => any;
    name?: string;
    refRef?: any;
}

const CheckboxLabel = ({ refRef, name, checkboxLabelText, checkboxLabelTitle, checkboxLabelStyle, checkboxChecked, checkboxOnClick, checkboxChanged, labelStyle, ...props }: CheckboxLabelProps) => {

    return (
        <Row>
            <Col colSize="col-xs-12 start-xs" colStyle={checkboxLabelStyle}>
                <Label labelSize="xsmall" labelStyle={labelStyle}>{checkboxLabelTitle}</Label>
                <Checkbox name={name} regRef={refRef} checkboxValue={checkboxChecked} checkboxOnChange={checkboxChanged} checkboxLabelText={checkboxLabelText} {...props} />
            </Col>
        </Row>
    )
}

export { CheckboxLabel };

