
import React from 'react'
// import { CopyrightBlock } from '../../organisms/CopyrightBlock';
import { palette } from '../../themes/index'
import { Row } from '../../atoms/Row';
import { Col } from '../../atoms/Col';

export interface SingleProps {
  storybook?: boolean;
  children?: any,
}

const NoUiTemplate = ({ storybook, children, ...props
}: SingleProps) => {
  return (
    <Row rowOptions={"center-xs"} rowAlignItemsCenter rowNoMargin rowStyle={{ minHeight: '90vh' }}>
      <Col colSize="col-xs-6 center-xs" colStyle={{ background: storybook ? palette.primary : "" }}>
        <Row rowAlignItemsCenter>
          <Col colSize="col-xs-12 center-xs">
            {children || "Body"}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default NoUiTemplate
