import React from 'react';
import { RequestListModel } from '../../../models/request';
import { firestore } from '../../../utils/firebase';
import { Checkbox } from '../../atoms/Checkbox';
import { Col } from '../../atoms/Col';
import { Label } from '../../atoms/Label';
import { Row } from '../../atoms/Row';
import { Table } from '../../atoms/Table';
import { TableBody } from '../../atoms/TableBody';
import { TableCell } from '../../atoms/TableCell';
import { TableHead } from '../../atoms/TableHead';
import { TableRow } from '../../atoms/TableRow';



export interface RequestListProps {

    rows?: RequestListModel[],
}

const RequestList = ({ rows }: RequestListProps) => {


    const handleAnswered = (docId: string, checked: boolean) => {

        if (docId) {
            const path = `/requests/${docId}`

            const data = {
                "answered": checked,
                "answeredOn": new Date()
            }

            firestore.doc(path).update(data)
        }

    }


    return (
        <Row>
            <Col colSize="col-xs-12" colStyle={{ overflow: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow >
                            <TableCell align="center"><Label labelBold labelSize="xsmall" labelUppercase>aanvrager naam</Label></TableCell>
                            <TableCell align="center"><Label labelBold labelSize="xsmall" labelUppercase>aanvrager email</Label></TableCell>
                            <TableCell align="center"><Label labelBold labelSize="xsmall" labelUppercase>adres</Label></TableCell>
                            <TableCell align="center"><Label labelBold labelSize="xsmall" labelUppercase>gsm</Label></TableCell>
                            <TableCell align="center"><Label labelBold labelSize="xsmall" labelUppercase>aangevraagd op</Label></TableCell>
                            <TableCell align="center"><Label labelBold labelSize="xsmall" labelUppercase>beantwoord</Label></TableCell>
                            <TableCell align="center"><Label labelBold labelSize="xsmall" labelUppercase>extra info</Label></TableCell>
                        </TableRow>

                    </TableHead>

                    <TableBody>
                        {rows && rows.map((doc) =>
                            (
                                <TableRow key={doc.id}>
                                    <TableCell align="center">{doc.name}</TableCell>
                                    <TableCell align="center">{doc.email}</TableCell>
                                    <TableCell align="center">{doc.adress}</TableCell>
                                    <TableCell align="center">{doc.gsm}</TableCell>
                                    <TableCell align="center">{(doc.requestedOn && doc.requestedOn.toDate() && doc.requestedOn.toDate().toLocaleString("nl-BE")) || ""}</TableCell>
                                    <TableCell align="center"><span title={(doc && doc.answered && doc.answeredOn && doc.answeredOn.toDate() && doc.answeredOn.toDate().toLocaleString("nl-BE")) || ""}> <Checkbox checkboxValue={doc.answered} checkboxOnChange={(e) => e && e.target && 'checked' in e.target && doc.id && handleAnswered(doc.id, e.target.checked)} />  </span> </TableCell>
                                    <TableCell align="center">{doc.extraInfo}</TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </Col>

        </Row>

    )
}


export { RequestList };

