import React from 'react';
import { Col } from '../../atoms/Col';
import { Label } from '../../atoms/Label';
import { Row } from '../../atoms/Row';
import { Space } from '../../atoms/Space';
import { IconButton } from '../../molecules/IconButton';
import RequestPage, { RequestPageProps } from '../../pages/RequestPage';
import { FotosFiles, PhotosFilesProps } from '../FotosFiles';
import { NewProduct, NewProductProps } from '../NewProduct';
import { NewSpecifications, NewSpecificationsProps } from '../NewSpecifications';
import { TopNavigation, TopNavigationProps } from '../TopNavigation';



interface ProductUIProps extends TopNavigationProps, NewSpecificationsProps, NewProductProps, RequestPageProps, PhotosFilesProps {
    canDelete?: boolean;
    id?: string;
    onGlobalDeleteClick?: () => any;
    onGlobalSaveClick?: () => any;
    path?: string;
}


const ProductSwitch = ({ canDelete, id, onGlobalDeleteClick, onGlobalSaveClick, selectedValue, ...props }: ProductUIProps) => {

    const TabVisible = (currentTab: string) => {
        return selectedValue === currentTab ? 'initial' : 'none'
    }

    return (
        <Row>
            <Col colSize="col-xs-12 center-xs">


                <Col colSize="col-xs-12 end-xs">
                    <Space />
                    <Row rowOptions={"end-xs"}>
                        <Col colSize="col-3">
                            {canDelete &&
                                <IconButton onClick={onGlobalDeleteClick} iconName="delete" iconButtonText={"Verwijderen"} />
                            }
                        </Col>
                        <Col colSize="col-1">
                            <Space spaceRight={"5px"} spaceLeft={"5px"} />
                        </Col>

                        <Col colSize="col-3">
                            <IconButton buttonPrimary iconName="save" onClick={onGlobalSaveClick} iconButtonText={"Publiceren"} />
                        </Col>
                    </Row>



                </Col>




                <Row rowAlignItemsCenter rowStyle={{
                    minHeight: '10vh'
                }}>

                    <Col colSize="col-xs-12 center-xs" colNoHorizontalPadding>
                        <Label labelSize="large">Overzicht product</Label>
                    </Col>
                </Row>

                <TopNavigation selectedValue={selectedValue} {...props} />
                <Space />
                <Space />
                <Space />
            </Col>
            <Col colSize="col-xs-12">
                <div style={{ display: TabVisible("algemene informatie") }}>
                    <NewProduct {...props} />
                </div>
                <div style={{ display: TabVisible("specificaties") }}>
                    <NewSpecifications {...props} />
                </div>
                <div style={{ display: TabVisible("fotos & files") }}>
                    <FotosFiles {...props} />
                </div>
                <div style={{ display: TabVisible("aanvragen") }}>
                    {id &&
                        <RequestPage id={id} {...props} />
                    }
                </div>
            </Col>


        </Row>
    )
};

export { ProductSwitch };

