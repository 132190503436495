import React from 'react';
import { Col } from '../../atoms/Col';
import { Dropdown } from '../../atoms/Dropdown';
import { Form } from '../../atoms/Form';
import { Row } from '../../atoms/Row';
import { Space } from '../../atoms/Space';
import { IconButton } from '../../molecules/IconButton';
import { InputLabel } from '../../molecules/InputLabel';


export interface RegistratioFormProps {
    onUserRegistrationFormSave?: () => any;
    onUserRegistrationFormBack?: () => any;
    regRef?: (params: any) => void;
    errors?: any;
    onSelectChange?: (e: any) => any;
    selectValues?: any;
}


export interface UserRegistrationFormDataScheme {
    email?: string;
    firstname?: string;
    familyname?: string;
    password?: string;
    birthdate?: Date;
    role?: "Architect" | "Ramenfabrikant" | "Aannemer" | "Andere" | "";
}

const UserRegistrationForm = ({ selectValues, onSelectChange, regRef, errors, onUserRegistrationFormSave, onUserRegistrationFormBack, ...props }: RegistratioFormProps) => {
    const records = [{ name: 'Architect', value: 'Architect' }, { name: 'Aannemer', value: 'Aannemer' }, { name: 'Ramenfabrikant', value: 'Ramenfabrikant' }, { name: 'Andere', value: 'Andere' }]


    return (
        <Form>
            <Row rowAlignItemsCenter>
                <Col colSize="col-xs-12 center-xs">
                    <InputLabel name="email" regRef={regRef} inputError={(errors && errors.email && errors.email.message) || ""} inputFullWidth inputType="email" inputLabel="E-mail" />
                    <InputLabel name="firstname" regRef={regRef} inputError={(errors && errors.firstname && errors.firstname.message) || ""} inputRequired inputFullWidth inputType="text" inputLabel="Voornaam" />
                    <InputLabel name="familyname" regRef={regRef} inputError={(errors && errors.familyname && errors.familyname.message) || ""} inputRequired inputFullWidth inputType="text" inputLabel="Achternaam" />
                    <InputLabel name="password" regRef={regRef} inputError={(errors && errors.password && errors.password.message) || ""} inputFullWidth inputRequired={true} inputType="password" inputLabel="Password" />
                    <Space />
                    <InputLabel name="birthdate" shrink regRef={regRef} inputError={(errors && errors.birthdate && errors.birthdate.message) || ""} inputRequired inputFullWidth inputType="date" inputLabel="Geboorte datum" />
                    <Space />
                    <Space />
                    <Dropdown dropdownName="role" dropdownDefaultValue={selectValues && selectValues.role} regRef={regRef} dropdownRecords={records} dropdownLabel="Functie" dropdownFullWidth dropdownOnChange={(e) => onSelectChange && onSelectChange(e)} />
                    <Space />
                </Col>
            </Row>
            <Row>
                <Col colSize="col-xs-6 start-xs">
                    <Space />
                    <IconButton buttonPrimary onClick={onUserRegistrationFormSave} iconName="save" iconButtonText="SAVE" />
                </Col>
                <Col colSize="col-xs-6 end-xs">
                    <Space />
                    <IconButton onClick={onUserRegistrationFormBack} iconName="arrow_back" iconButtonText="Back" />
                </Col>
            </Row>
        </Form>
    )
}


export { UserRegistrationForm };

