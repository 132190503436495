import { FormControl, FormControlLabel } from '@material-ui/core';
import { default as MCheckbox } from '@material-ui/core/Checkbox';
import React from 'react';
import { fonts, palette } from '../../themes/index';


export interface CheckboxProps {
  checkboxValue?: any;
  checkboxChecked?: boolean;
  checkboxStyle?: any;
  checkboxDisabled?: boolean;
  checkboxSelected?: boolean;
  checkboxLabelText?: string;
  checkboxOnChange?: (value: any) => any;
  name?: string;
  regRef?: any;
}


const Checkbox = ({ regRef, name, checkboxValue, checkboxStyle, checkboxChecked, checkboxDisabled, checkboxSelected, checkboxLabelText, checkboxOnChange }: CheckboxProps) => {
  const checkedColor = checkboxChecked ? palette.grayscale : ''
  return (
    <FormControl component="fieldset">
      <FormControlLabel
        onChange={checkboxOnChange}
        checked={checkboxValue}
        control={
          <MCheckbox
            inputRef={regRef}
            name={name}
            disabled={checkboxDisabled}
            style={{
              fontFamily: fonts.primary,
              color: checkedColor,
              ...checkboxStyle
            }}
            onChange={checkboxOnChange}
            value={checkboxValue}
          />
        }
        label={checkboxLabelText}
        style={{
          userSelect: 'none'
        }}
        labelPlacement="end"
      />
    </FormControl>
  )
};

export { Checkbox };

