import React from 'react';
import { Button, ButtonProps } from '../../atoms/Button';
import { Col } from '../../atoms/Col';
import { Label, LabelProps } from '../../atoms/Label';
import { Row } from '../../atoms/Row';
import { palette } from '../../themes/index';

export interface SideMenuProps extends ButtonProps, LabelProps {
    navigationText?: string;
    selected?: boolean;
    sideMenuOnClick?: () => any;
}

const SideMenu = ({ sideMenuOnClick, navigationText, selected, ...props }: SideMenuProps) => {
    return (
        <Row>
            <Col colSize="col-xs-12 col-lg-12"  >
                <Button onClick={sideMenuOnClick} buttonNoRippleEffect  {...props} buttonStyle={{ display: "flex", justifyContent: "left", backgroundColor: 'transparent', minWidth: "200px", textAlign: "left" }}>
                    <Label labelStyle={{ color: selected ? palette.black : palette.lightBlack }} >{navigationText}</Label>
                </Button>
            </Col>
        </Row>
    )
}

export { SideMenu };
