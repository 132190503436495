import React from 'react';
import { Icon } from '../../atoms/Icon';
import { Label } from '../../atoms/Label';
import { Space } from '../../atoms/Space';
import SingleTemplate from '../../templates/SingleTemplate';
import { palette } from '../../themes';


const NotFoundPage = () => {
  return (
    <SingleTemplate
      singleTitle={<Label labelStyle={{ color: palette.grey }}>Error Request</Label>}
      singleFooter={<Label labelSize="small">Copyrights 2019</Label>}
    >
      <Icon iconSize="xxxlarge" iconName="error" />
      <Space />
      <Label labelSize="large">Can Not Found Requested Page</Label>
    </SingleTemplate >
  )
}

export default NotFoundPage