
import React from 'react';
import { Col } from '../../atoms/Col';
import { Dropdown } from '../../atoms/Dropdown';
import { Form } from '../../atoms/Form';
import { Row } from '../../atoms/Row';
import { Space } from '../../atoms/Space';
import { IconButton } from '../../molecules/IconButton';
import { InputLabel } from '../../molecules/InputLabel';

export interface EditAddProfileProps {
    onProfileSave?: (e: any) => any
    onProfileLogOut?: (e: any) => any
    regRef?: (params: any) => void;
    errors?: any;
    onSelectChange?: (e: any) => any;
    selectValues?: any;
}


export interface ProfileDataScheme {
    firstname?: string
    familyname?: string;
    email?: string;
    birthdate?: string;
    address?: string;
    telephone?: string;
    country?: string;
    role?: string;
    company?: string;
}


const EditAddProfile = ({ selectValues, onSelectChange, onProfileSave, onProfileLogOut, regRef, errors, ...props }: EditAddProfileProps) => {
    const records = [{ name: 'Architect', value: 'Architect' }, { name: 'Aannemer', value: 'Aannemer' }, { name: "Ramenfabrikant", value: "Ramenfabrikant" }, { name: "Andere", value: "Andere" }]
    return (
        <Form>
            <Row rowAlignItemsCenter>

                <Col colSize="col-xs-12 center-xs">
                    <InputLabel name="firstname" shrink regRef={regRef} inputError={(errors && errors.firstname && errors.firstname.message) || ""} inputRequired inputFullWidth inputType="text" inputLabel="Voornaam" />
                    <InputLabel name="familyname" shrink regRef={regRef} inputError={(errors && errors.familyname && errors.familyname.message) || ""} inputRequired inputFullWidth inputType="text" inputLabel="Achternaam" />
                    <InputLabel name="email" shrink regRef={regRef} inputError={(errors && errors.email && errors.email.message) || ""} inputDisabled inputFullWidth inputType="email" inputLabel="E-mail" />
                    <Space />
                    <InputLabel name="birthdate" shrink regRef={regRef} inputError={(errors && errors.birthdate && errors.birthdate.message) || ""} inputRequired inputFullWidth inputType="date" inputLabel="Geboorte datum" />
                    <InputLabel name="address" shrink regRef={regRef} inputError={(errors && errors.address && errors.address.message) || ""} inputRequired inputFullWidth inputType="text" inputLabel="Adres" />
                    <InputLabel name="telephone" shrink regRef={regRef} inputError={(errors && errors.telephone && errors.telephone.message) || ""} inputRequired inputFullWidth inputType="text" inputLabel="Gsm" />
                    <Space />
                    <InputLabel name="country" shrink regRef={regRef} inputError={(errors && errors.country && errors.country.message) || ""} inputRequired inputFullWidth inputType="text" inputLabel="Land" />
                    <Space />
                    <Space />
                    <Dropdown dropdownName="role" dropdownDefaultValue={selectValues && selectValues.role} regRef={regRef} dropdownRecords={records} dropdownLabel="Functie" dropdownFullWidth dropdownOnChange={(e) => onSelectChange && onSelectChange(e)} />
                    <Space />
                    <InputLabel name="company" shrink regRef={regRef} inputError={(errors && errors.company && errors.company.message) || ""} inputRequired inputFullWidth inputType="text" inputLabel="Bedrijfsnaam" />
                    <Space />
                    <Space />
                </Col>
            </Row>
            <Row>
                <Col colSize="col-xs-6 start-xs">
                    <Space />
                    <IconButton buttonPrimary iconName="save" iconButtonText="SAVE" onClick={onProfileSave} />
                </Col>
                <Col colSize="col-xs-6 end-xs">
                    <Space />
                    <IconButton iconName="exit_to_app" iconButtonText="LOGOUT" onClick={onProfileLogOut} />
                </Col>
            </Row>
        </Form>
    )
}


export { EditAddProfile };

