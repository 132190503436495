import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FileItem } from '../../../models/products';
import { Col } from '../../atoms/Col';
import { Row } from '../../atoms/Row';
import { Space } from '../../atoms/Space';

export interface FileCardProps extends RouteComponentProps {
    cards: FileItem[];
    onDelete?: (fullPath: string) => any
    imagePreview?: boolean;
}

const FileCard = ({ imagePreview, onDelete, cards, history }: FileCardProps) => {
    return (
        <Row rowAlignItemsCenter rowOptions="center-xs">
            {cards.map(({ url, fileName, fullPath }) => (
                <Col colSize="col-xs-7 center-xs" key={fullPath || url || ""}>
                    <Card>
                        <CardActionArea>
                            <CardMedia
                                style={{ height: 200 }}
                                image={imagePreview ? url || '/no-image.png' : '/pdf.png'}
                                title={fileName || ""}
                            />
                            <CardContent>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {fileName || ""}
                                </Typography>

                            </CardContent>
                        </CardActionArea>
                        <CardActions style={{ justifyContent: 'center' }}>
                            <Button size="small" color="primary" onClick={() => onDelete && fullPath && onDelete(fullPath)}>
                                Delete
                            </Button>
                        </CardActions>
                    </Card>
                    <Space />
                    <Space />
                    <Space />
                </Col>
            ))}
        </Row>

    )
}

export default withRouter(FileCard);
