import { History } from 'history'
import React, { useEffect, useState } from 'react'
import useForm from 'react-hook-form'
import * as yup from 'yup'
import { auth, firestore } from '../../../utils/firebase'
import { UserRegistrationForm, UserRegistrationFormDataScheme } from '../../organisms/UserRegistrationForm'
import NoUiTemplate from '../../templates/NoUiTemplate'
import { palette } from '../../themes'

interface RegistrationFormProps {
  history?: History
}

const RegistrationPage = ({ history }: RegistrationFormProps) => {

  //basic error
  const [regError, setRegError] = useState(undefined)

  //validation scheme
  const RegistrationValidationScheme = yup.object().shape({
    firstname: yup.string().required("veld is verplicht"),
    familyname: yup.string().required("veld is verplicht"),
    password: yup.string().required("veld is verplicht").min(7, "wachtwoord moet minstens 7 tekens lang zijn"),
    email: yup.string().required("veld is verplicht").email("veld moet email zijn"),
    birthdate: yup.date().typeError("veld moet geldig datum zijn").required("veld is verplicht"),
    role: yup.string().oneOf(["", "Architect", "Aannemer", "Ramenfabrikant", "Andere"])
  })

  //form hook
  const { register, watch, errors, setValue, triggerValidation } = useForm({
    mode: "onBlur",
    validationSchema: RegistrationValidationScheme
  })

  //selects callback
  const [selectedValues, setSelectValue] = useState({ role: "" })
  const handleMultiChange = (selectedOption: any) => {
    console.log(selectedOption.target.value, "role", selectedOption.target.name)
    setValue(selectedOption.target.name, selectedOption.target.value)
    setSelectValue({ role: selectedOption.target.value })
  }
  useEffect(() => {
    register({ name: "role" })
    setValue('role', '')
  }, [register, setValue])


  //user registration
  const registerUser = async () => {
    try {

      const { password, ...data }: UserRegistrationFormDataScheme = {
        ...watch()
      }

      if (data.email && password) {
        const ret = await auth.createUserWithEmailAndPassword(data.email, password)
        if (ret && ret.user && ret.user.uid) {
          await firestore.collection('profiles').add({ ...data, userId: ret.user.uid })
          setRegError(undefined)
          history && history.push("/")
        } else {
          throw new Error("can not get user's uid")
        }
      } else {
        throw new Error("empty fields")
      }
    } catch (err: any) {
      setRegError(err["message"] || "unknown")
    }
  }

  return (
    <NoUiTemplate>
      {regError &&
        <div><p style={{ color: palette.danger }}>Error: {regError}</p></div>
      }
      <UserRegistrationForm
        errors={errors}
        regRef={register}
        onUserRegistrationFormSave={async () => {
          const validated = await triggerValidation()
          if (validated === true) {
            console.log(validated, "form validation result")
            registerUser()
          }
        }
        }
        onUserRegistrationFormBack={() => history && history.push("/login")}
        onSelectChange={handleMultiChange}
        selectValues={selectedValues}
      />
    </NoUiTemplate >
  )
}

export default RegistrationPage