import React from 'react';
import { Button } from '../../atoms/Button';
import { Col } from '../../atoms/Col';
import { Form } from '../../atoms/Form';
import { Row } from '../../atoms/Row';
import { Space } from '../../atoms/Space';
import { IconButton } from '../../molecules/IconButton';
import { InputLabel } from '../../molecules/InputLabel';


interface LoginFormProps {
    onRegisterClick?: () => any
    onLoginClick?: (e: any) => any
    onRecoveryClick?: (e: any) => any
    regRef?: (params: any) => void;
    errors?: any;
}

export interface LoginFormDataScheme {
    username?: string
    password?: string;
}

const LoginForm = ({ onRecoveryClick, errors, regRef, onLoginClick, onRegisterClick }: LoginFormProps) => {
    return (
        <Form>
            <Row>
                <Col colSize="col-xs-12 col-lg-12 center-xs">
                    {errors && errors.username && <span>{errors.username.message || "Fout"}</span>}
                    <InputLabel regRef={regRef} name="username" inputFullWidth inputType="text" inputLabel="Gebruikersnaam" />
                </Col>
                <Col colSize="col-xs-12 col-lg-12 center-xs">
                    {errors && errors.password && <span>{errors.password.message || "Fout"}</span>}
                    <InputLabel regRef={regRef} name="password" inputFullWidth inputType="password" inputLabel="Wachtwoord" />
                    <Space />
                </Col>
                <Col colSize="col-xs-12 center-xs">
                    <Space />
                    <IconButton buttonFullWidth iconButtonText={'Login'} onClick={onLoginClick} iconName="account_circle" />
                </Col>
                <Col colSize="col-xs-12 end-xs">
                    <Space />
                    <Button buttonFullWidth onClick={onRegisterClick}>Register</Button>
                </Col>
            </Row>
            <Row>
                <Col colSize="col-xs-12 center-xs">
                    <Space />
                    <Space />
                    <Space />
                    <a href="/recover" onClick={onRecoveryClick}>Wachtwoord Vergeten</a>
                </Col>
            </Row>
        </Form>

    )
}

export { LoginForm };

