import { History } from 'history';
import React, { useContext, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection } from 'react-firebase-hooks/firestore';
import { match } from 'react-router';
import UserContext from '../../../utils/context';
import { auth, firestore } from '../../../utils/firebase';
import { ProductList } from '../../organisms/ProductList';
import { SideNavigation } from '../../organisms/SideNavigation';
import AdminTemplate from '../../templates/AdminTemplate';


export interface AdminPoductListPageProps {
    history?: History;
    match?: match<{ id: string }>
    admin?: boolean;
}


const PoductListPage = ({ match, history, ...props }: AdminPoductListPageProps) => {

    const [user] = useAuthState(auth)

    let userData = useContext(UserContext)

    const [snapshot] = useCollection(userData.admin ? firestore.collection("products") : firestore.collection("products").where("userId", "==", user && user.uid));

    const [searchText, setSeatchText] = useState("")
    const [filterOption, setFilterOption] = useState("")

    const retData = snapshot && snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        .map((item: any) => ({ ...item, articleNameS: (item.articleName || '').toLocaleLowerCase() }))
        .filter((itemB: any) => ((itemB.articleNameS.includes(searchText.toLocaleLowerCase()))))
        .filter((itemA: any) => filterOption !== "" ? itemA.status && itemA.status === filterOption : true)

    const sortedretData = retData && retData.sort((a: any, b: any) => {
        const left = (a && a.creationDate && a.creationDate.toMillis()) || 0
        const right = (b && b.creationDate && b.creationDate.toMillis()) || 0
        return right - left
    })

    return (

        <AdminTemplate
            adminNavmenu={<SideNavigation sideNavigationSelectedValue={"Producten"} sideNavigationOnClick={(menu) => history && history.push(menu)} />}
            contentFullSize
        >
            <ProductList rows={sortedretData || []}
                productOnAddClick={async () => {
                    try {
                        history && history.push(`/products/new`)
                    } catch (error) {
                        console.log(error)
                    }
                }}
                productEditOnclick={(documentId: string) => {
                    history && history.push(`/products/${documentId}`)
                }}
                onSearchInputChange={(e: any) => setSeatchText(e.currentTarget.value || "")}
                searchInputValue={searchText}
                onFilterChange={(e: any) => setFilterOption(e.target.value || "")}
                filterValue={filterOption}
            />
        </AdminTemplate>
    )
}


export default PoductListPage 